import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CeoSection = () => {
  return (
    <section id="ceo" className="black">
      <div>
        <StaticImage
          className="img"
          src="../../images/new-web/arrow.svg"
          alt="Handshake"
          placeholder="blurred"
        />
        <h3>Naším poslaním je pomáhať vám rýchlejšie napredovať.</h3>
        <p>
          <q>
            Každý potrebuje partnera, ktorý mu pomôže posunúť sa ďalej, hrá
            fair-play, komunikuje priamo a má dostupné ceny. Verím, že pre vás
            budeme práve my takým partnerom.
          </q>
        </p>
        <p>Martin Tóth, konateľ</p>
      </div>
      <div>
        <StaticImage
          className="img"
          src="../../images/new-web/ceo01.jpg"
          alt="CEO"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};
export default CeoSection;
