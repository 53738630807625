import React from "react";

//above-the-fold
import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionHomepage from "../components/homepage/mainSection.js";

//below-the-fold
import "../styles/home.scss";
import PartnersStripeWhite from "../components/homepage/partnersStripeWhite.js";
import SluzbyShowcase from "../components/homepage/sluzbyShowcase.js";
import SolutionsSection from "../components/homepage/solutionsSection.js";
import References from "../components/homepage/references.js";
import CompaniesStripe from "../components/homepage/companiesStripe";
import ContactUs from "../components/contactUs";
import CeoSection from "../components/homepage/ceoSection";
/* import CalendlySection from "../components/homepage/calendlySection"; */
import VideoReferences from "../components/homepage/videoReferences";

const IndexPage = () => {
  return (
    <Layout pageName="homepage-main">
      <SEO title="Digitálne produkty: Aplikácie, Informačné systémy, Programovanie na mieru" />
      <MainSectionHomepage />
      <PartnersStripeWhite />
      <VideoReferences />
      <SluzbyShowcase />
      <SolutionsSection />
      <References />
      <CompaniesStripe />
      {/*<Faq />*/}
      <CeoSection />
      <ContactUs
        text={
          <>
            Vývoj softvéru pohodlne
            <br /> a profesionálne
          </>
        }
        button="Položiť otázku"
      />
      {/* <CalendlySection /> */}
      {/* Skript pre videa */}
      <script defer type="text/plain" data-cookie-category="marketing">
        {`
        var player;
        var player1;
        var player2;
        
        if (window.YT) {
          player = new YT.Player("ytplayer", {
            height: 236,
            width: 420,
            videoId: "sVN1cu7S4bw",
            allowfullscreen: !0,
            autoplay: !0,
            playerVars: { rel: 0, controls: 0 },
          });
          player1 = new YT.Player("ClCBkuDjI8s", {
            height: 236,
            width: 420,
            videoId: "ClCBkuDjI8s",
            allowfullscreen: !0,
            autoplay: !0,
            playerVars: { rel: 0, controls: 0 },
          });
          player2 = new YT.Player("s5QfFaMfkmc", {
            height: 236,
            width: 420,
            videoId: "s5QfFaMfkmc",
            allowfullscreen: !0,
            autoplay: !0,
            playerVars: { rel: 0, controls: 0 },
          });
        } else {
          window.onYouTubePlayerAPIReady = function () {
            player = new YT.Player("ytplayer", {
              height: 236,
              width: 420,
              videoId: "sVN1cu7S4bw",
              allowfullscreen: !0,
              autoplay: !0,
              playerVars: { rel: 0, controls: 0 },
            });
            player1 = new YT.Player("ClCBkuDjI8s", {
              height: 236,
              width: 420,
              videoId: "ClCBkuDjI8s",
              allowfullscreen: !0,
              autoplay: !0,
              playerVars: { rel: 0, controls: 0 },
            });
            player2 = new YT.Player("s5QfFaMfkmc", {
              height: 236,
              width: 420,
              videoId: "s5QfFaMfkmc",
              allowfullscreen: !0,
              autoplay: !0,
              playerVars: { rel: 0, controls: 0 },
            });
          };
          var tag = document.createElement("script");
          tag.src = "https://www.youtube.com/player_api";
          var firstScriptTag = document.getElementsByTagName("script")[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }   
`}
      </script>
    </Layout>
  );
};

export default IndexPage;
