import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "/src/styles/lazy-loader.scss";

const CompaniesStripe = () => {
  return (
    <section id="companies">
      <h3>
        Dôverujú nám firmy
        <br /> s 20 ročnou tradíciou
      </h3>
      <ul>
        <li>
          <StaticImage
            className="img"
            src="../../images/new-web/sony_logo_white.svg"
            alt="sony"
            placeholder="blurred"
          />
        </li>
        <li>
          <StaticImage
            className="img"
            src="../../images/new-web/minimax_white.svg"
            alt="minimax"
            placeholder="blurred"
          />
        </li>
        <li>
          <StaticImage
            className="img"
            src="../../images/new-web/proplusco_white.svg"
            alt="proplusco"
            placeholder="blurred"
          />
        </li>
        <li>
          <StaticImage
            className="img"
            src="../../images/new-web/kumat_white.svg"
            alt="kumat"
            placeholder="blurred"
          />
        </li>
      </ul>
    </section>
  );
};
export default CompaniesStripe;
