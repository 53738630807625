import React from "react"
import { Link } from "gatsby";
import LottieImage from "../lottieImage";
import { useInView } from "react-cool-inview";




const SolutionBox = (props) => {

    const { observe, inView } = useInView({ unobserveOnEnter: true });
        
    const content = (
        <div className="solution-description">
            <h3>{props.heading}</h3>
            <p dangerouslySetInnerHTML={{__html: props.desc}}></p>
        </div>
    )
    
    return (
        <div className={ "solution-box " + ((inView) ? "loaded" : "") } ref={observe}>

            {props.align == "right" && content}

            <div className="solution-img">
            {inView &&
                <LottieImage
                    data={props.lottieAnim}
                />
            }
            </div>

            {props.align == "left" && content}
            
        </div>
    )
        

}

export default SolutionBox;