import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Scrollbar, Autoplay } from "swiper";

import "swiper/scss"
import "swiper/scss/pagination"
import "swiper/scss/scrollbar"

const References = () => {

    return(
        <section id="reference">
            <Swiper
                modules={[Pagination, Scrollbar, Autoplay]}
                slidesPerView={1}
                spaceBetween={2}
                loop={true}
                direction={'horizontal'}
                pagination={{clickable: true}}
                autoplay={{ delay: 20000}}
            >
            <SwiperSlide>
                    <div className="ref-img">
                       <StaticImage className="img" src="../../images/new-web/ref03.png" alt="Autor referencie" placeholder="blurred" />
                       <div className="desc-flex">
                                <p>Ing. Marián Hrčka</p>
                                <p>Spoluzakladateľ SNEHA</p>
                       </div>
                    </div>
                   <div className="ref-desc">
                       <p>Ja osobne som perfekcionista a mám rád, ak dohodnuté veci platia. Teší ma, že sme našli spoločnosť ako OBS, kde presne chápu naše potreby a majú skvelý prístup. Nielenže vždy spravia to čo sme sa dohodli, ale ešte aj veľakrát veci najviac, ochotne poradia alebo pomôžu tam, kde my si rady nevieme. Nie je dnes veľa spoločností s takýmto prístupom. Veľká vďaka za všetko, čo pre nás robíte.</p>
                       <div className="desc-flex">
                            <StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" placeholder="blurred" />
                            <div>
                                <p>Ing. Marián Hrčka</p>
                                <p>Spoluzakladateľ SNEHA</p>
                            </div>
                       </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="ref-img">
                       <StaticImage className="img" src="../../images/new-web/ref01.jpeg" alt="Autor referencie" placeholder="blurred" />
                       <div className="desc-flex">
                                <p>Ing. Barbora Feketeová</p>
                                <p>Marketingová Manažérka, Proplusco a PLUSIM</p>
                       </div>
                    </div>
                   <div className="ref-desc">
                       <p>Spolupráca s OBS Slovakia a Martinom na projektoch týkajúcich sa webových aplikácií, PPC, SEO, e-shopu a Klientskej zóne bola excelentná. Kooperácia bola veľmi hladká a ich pomoc bola obrovským prínosom pre úspech našich spoločností.</p>
                       <div className="desc-flex">
                            <StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" placeholder="blurred" />
                            <div>
                                <p>Ing. Barbora Feketeová</p>
                                <p>Marketingová Manažérka, Proplusco a PLUSIM</p>
                            </div>
                       </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="ref-img">
                       <StaticImage className="img" src="../../images/new-web/ref02.jpg" alt="Autor referencie" placeholder="blurred" />
                       <div className="desc-flex">
                                <p>Gabriel Ludvik</p>
                                <p>Majiteľ a CEO, MicroIT</p>
                       </div>
                    </div>
                   <div className="ref-desc">
                       <p>Práca s OBS Slovakia bola (a stále je) na vysokej profesionálnej úrovni. Od začiatku tvorby nového webu prichádzajú so skvelými nápadmi, inováciami a promptným servisom. Náš web <a href="https://microit-gts.com/" target="blank">MicroIT-GTS.com</a> spĺňa presne to čo sme od neho očakávali. Vďaka OBS je moderný, zaujímavý a vystihuje nás ako firmu a nás ako ľudí. OBS ma naše vrelé odporúčania.</p>
                       <div className="desc-flex">
                            <StaticImage className="img" src="../../images/new-web/arrow.svg" alt="Šípka vpravo" placeholder="blurred" />
                            <div>
                                <p>Gabriel Ludvik</p>
                                <p>Majiteľ a CEO, MicroIT</p>
                            </div>
                       </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default References;