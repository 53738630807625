import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const PartnersStripeWhite = () => {
  return (
    <section id="main-partners">
      <Swiper
        className="logo-slider"
        spaceBetween={20}
        modules={[Autoplay]}
        loop={true}
        autoplay={{ delay: 0, disableOnInteraction: false }}
        slidesPerView={6}
        speed={3000}
      >
        {/* Slides */}
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/ihlove-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/pestucci-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/comextrans_black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/ecomed-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/signix-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/louloudi-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/jtf-logo-black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/prespor-logo-black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/novitera-logo-black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/plusim-logo-black.png"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/minimax-logo-black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/proplusco_black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/kumat_black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/microit_black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/hotspring-logo-black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        <SwiperSlide>
          <StaticImage
            className="img"
            src="../../images/new-web/sony_logo_black.svg"
            alt="Client"
            placeholder="blurred"
          />
        </SwiperSlide>
        {/* Repeat your slides as needed */}
      </Swiper>
    </section>
  );
};

export default PartnersStripeWhite;
