import { Link } from "gatsby";
import React, { useState, useEffect /*, lazy, Suspense*/ } from "react";
import { StaticImage } from "gatsby-plugin-image";
//import YTvideo from './YTvideo.js'

/*const YTvideo = lazy(() => import('./YTvideo.js'));

//const renderLoader = () => <div id="ytplayer"></div>;*/

const MainSectionHomepage = () => {
  /* COUNTDOWN ANIMÁCIA */
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  /* H1 ANIMÁCIA */
  const [visibleIndex, setVisibleIndex] = useState(1);

  useEffect(() => {
    /* H1 animácia */
    const interval = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex % 3) + 1);
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array, so it runs once when component is mounted

  useEffect(() => {
    if (counter2 < 250) {
      setTimeout(() => {
        if (counter1 < 114) {
          setCounter1((counter1) => counter1 + 1);
        }
        setCounter2((counter2) => counter2 + 1);
      }, 5);
    }
  }, [counter2]);

  const videoCookieClick = () => {
    if (window.cookiesGuard.marketing == "") {
      //ak nie, tak zobraz cookie výber
      window.cookiesGuard.showPopUp();
    }
  };

  return (
    <section id="homepage" className="products-top section-dark">
      <div className="banner">
        <div className="banner-img">
          <div
            id="videoWrapper"
            className="video-c"
            onClick={() => videoCookieClick()}
          >
            {/*<Suspense fallback={renderLoader()}>
                  <YTvideo />
                </Suspense>*/}
            {/* Skript pre videa sa nachadza v súbore index.js !!! */}
            <div id="ytplayer"></div>
          </div>

          {/* <script defer type="text/plain" data-cookie-category="marketing">
            {`var player;window.onYouTubePlayerAPIReady=function(){player=new YT.Player("ytplayer",{height:236,width:420,videoId:"sVN1cu7S4bw",allowfullscreen:!0,autoplay:!0,playerVars:{rel:0,controls:0}})};var tag=document.createElement("script");tag.src="https://www.youtube.com/player_api";var firstScriptTag=document.getElementsByTagName("script")[0];firstScriptTag.parentNode.insertBefore(tag,firstScriptTag);`}
          </script> */}

          <ul>
            <li id="countdown-1">+{counter1}%</li>
            <li id="icon01">
              <StaticImage
                src="../../images/new-web/click-icon.svg"
                alt="Mouse click"
                placeholder="blurred"
              />
            </li>
            <li id="countdown-2">+{counter2}%</li>
            <li id="icon02">
              <StaticImage src="../../images/new-web/eye-icon.svg" alt="Eye" />
            </li>
            <li id="icon03">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.87"
                height="26.182"
                viewBox="0 0 26.87 26.182"
              >
                <g
                  id="left-arrow"
                  transform="translate(26.87 -6.312) rotate(90)"
                >
                  <path
                    id="Path_1412"
                    data-name="Path 1412"
                    d="M32.494,12.3a1.713,1.713,0,0,0-1.716-1.716H16.557l6.034-6.034a1.729,1.729,0,0,0,0-2.432L20.971.5a1.729,1.729,0,0,0-2.432,0L6.816,12.223a1.709,1.709,0,0,0,0,2.424L18.539,26.37a1.729,1.729,0,0,0,2.432,0l1.62-1.62a1.729,1.729,0,0,0,0-2.432L16.557,16.3H30.77a1.719,1.719,0,0,0,1.716-1.716Z"
                    transform="translate(0 0)"
                    fill="#1a84f0"
                  />
                </g>
              </svg>
            </li>
            <li id="icon04">
              <svg
                id="satisfaction_1_"
                data-name="satisfaction (1)"
                xmlns="http://www.w3.org/2000/svg"
                width="34.137"
                height="34.137"
                viewBox="0 0 34.137 34.137"
              >
                <path
                  id="Path_1408"
                  data-name="Path 1408"
                  d="M34.137,17.068a6.343,6.343,0,0,0-1.618-4.235A4.714,4.714,0,0,1,31.47,9.068a6.408,6.408,0,0,0-6.4-6.4A4.7,4.7,0,0,1,21.3,1.619a6.35,6.35,0,0,0-8.47,0A4.706,4.706,0,0,1,9.068,2.667,6.408,6.408,0,0,0,2.691,9.622a4.284,4.284,0,0,1-1.072,3.212,6.35,6.35,0,0,0,0,8.47,4.714,4.714,0,0,1,1.048,3.766,6.407,6.407,0,0,0,6.955,6.377,4.264,4.264,0,0,1,3.212,1.072,6.35,6.35,0,0,0,8.47,0,4.7,4.7,0,0,1,3.766-1.048,6.408,6.408,0,0,0,6.377-6.955A4.284,4.284,0,0,1,32.518,21.3a6.343,6.343,0,0,0,1.618-4.235Zm-3.606,2.457a6.961,6.961,0,0,0-1.742,5.219,3.738,3.738,0,0,1-3.72,4.059,7.093,7.093,0,0,0-5.544,1.728,3.683,3.683,0,0,1-4.913,0,6.928,6.928,0,0,0-5.219-1.742,3.738,3.738,0,0,1-4.059-3.72,7.164,7.164,0,0,0-1.728-5.544,3.683,3.683,0,0,1,0-4.913A6.961,6.961,0,0,0,5.348,9.393a3.738,3.738,0,0,1,3.72-4.059,7.093,7.093,0,0,0,5.544-1.728,3.683,3.683,0,0,1,4.913,0,7.133,7.133,0,0,0,5.544,1.728A3.738,3.738,0,0,1,28.8,9.068a7.164,7.164,0,0,0,1.728,5.544,3.683,3.683,0,0,1,0,4.913Z"
                  fill="#e0e0e0"
                />
                <path
                  id="Path_1409"
                  data-name="Path 1409"
                  d="M185.467,206.249l-1.724-1.724-1.886,1.886,3.61,3.61,6.277-6.277-1.886-1.886Z"
                  transform="translate(-169.732 -188.399)"
                  fill="#e0e0e0"
                />
                <path
                  id="Path_1410"
                  data-name="Path 1410"
                  d="M106.668,96a10.668,10.668,0,1,0,10.668,10.668A10.68,10.68,0,0,0,106.668,96Zm0,18.669a8,8,0,1,1,8-8A8.01,8.01,0,0,1,106.668,114.669Z"
                  transform="translate(-89.599 -89.599)"
                  fill="#e0e0e0"
                />
              </svg>
            </li>
          </ul>
        </div>
        <h1>
          Tvoríme Digitálne produkty
          <span className="sub-heading">od nápadu po uvedenie na trh</span>
          <div className="animationWrapper">
            <span
              id="span1"
              className={
                visibleIndex === 1
                  ? "fade-in heading-span"
                  : "hidden heading-span"
              }
            >
              Aplikácie, Informačné systémy, Webstránky
            </span>
            <span
              id="span2"
              className={
                visibleIndex === 2
                  ? "fade-in heading-span"
                  : "hidden heading-span"
              }
            >
              e-Shopy, B2B a Zákaznícke portály
            </span>
            <span
              id="span3"
              className={
                visibleIndex === 3
                  ? "fade-in heading-span"
                  : "hidden heading-span"
              }
            >
              CRM systémy a IT Outsourcing
            </span>
          </div>
        </h1>
        <Link to="/referencie">Referencie</Link>
        {/*<a href="<?php echo  BASE_URL . $app->translate('projekty/', 'projects/') ?>"></a>*/}
      </div>
    </section>
  );
};

export default MainSectionHomepage;
