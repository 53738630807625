import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";

import SVG1 from "../../images/new-web/web.svg";
import SVG2 from "../../images/new-web/app.svg";
import SVG3 from "../../images/new-web/informacne_systemy.svg";

//swiper plugin na mobile
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, Autoplay } from "swiper";

import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";

const SluzbyShowcase = () => {
  const [currentSlide, setSlide] = useState(1);

  //či uživateľ začal ovládať slider
  const userControl = useRef(false);

  //auto-slide, pokiaľ nezačal user ovládať slideovanie
  useEffect(() => {
    if (!userControl.current) {
      const timer = window.setTimeout(function() {
        changeToSlide(currentSlide + 1);
      }, 20000);
      return () => clearTimeout(timer);
    }
  });

  const changeToSlide = (num) => {
    if (num > 6) {
      num = 1;
    } else if (num < 1) {
      num = 6;
    }
    setSlide(num);
  };

  const clickControl = (num) => {
    userControl.current = true;
    changeToSlide(num);
  };

  return (
    <>
      <section id="sluzby">
        <div className="services-list">
          <h2>Čo pre vás vieme spraviť</h2>
          <ul>
            <li
              className="arrow-up"
              onClick={() => clickControl(currentSlide - 1)}
            >
              <StaticImage
                className="arrow-down img"
                onclick="customSlider()"
                src="../../images/new-web/arrow.svg"
                alt="Šípka hore"
                placeholder="blurred"
              />
            </li>
            <li
              className={"slide-1 " + (currentSlide == 1 ? "active" : "")}
              onClick={() => clickControl(1)}
            >
              Webstránka
            </li>
            <li
              className={"slide-2 " + (currentSlide == 2 ? "active" : "")}
              onClick={() => clickControl(2)}
            >
              Eshop
            </li>
            <li
              className={"slide-3 " + (currentSlide == 3 ? "active" : "")}
              onClick={() => clickControl(3)}
            >
              Informačný systém
            </li>
            <li
              className={"slide-4 " + (currentSlide == 4 ? "active" : "")}
              onClick={() => clickControl(4)}
            >
              Klientská zóna a B2B portál
            </li>
            <li
              className={"slide-5 " + (currentSlide == 5 ? "active" : "")}
              onClick={() => clickControl(5)}
            >
              Mobilná aplikácia
            </li>
            <li
              className={"slide-6 " + (currentSlide == 6 ? "active" : "")}
              onClick={() => clickControl(6)}
            >
              IT Outsourcing
            </li>
            <li
              className="arrow-down"
              onClick={() => clickControl(currentSlide + 1)}
            >
              <StaticImage
                className="arrow-up img"
                onclick="customSlider()"
                src="../../images/new-web/arrow.svg"
                alt="Šípka dole"
                placeholder="blurred"
              />
            </li>
          </ul>
        </div>
        <div
          className={
            "services-description slide-1 " +
            (currentSlide == 1 ? "active" : "")
          }
        >
          <img class="img" src={SVG1} alt="Webstránka" loading="lazy" />
          <h3>Webstránka</h3>
          <p>
            Potrebujete pomôcť s výrobou webstránky? Vytvoríme pre vás
            jednoduchú, ale aj zložitú webstránku. Zvládneme aj šablónovú
            webstránku, Wordpress, ale aj webstránku s grafikou na mieru.
          </p>
          <p>
            Popasujeme sa pre vás s jednoduchou prezentačnou webstránkou, ale aj
            komplexnou webstránkou prepojenou s účtovníctvom, skladom,
            programami tretích strán a API prepojeniami.
          </p>
          <p>
            Sme komplexný dodávateľ a partner webstránok. Žiadny projekt pre nás
            nie je malý, aby sme ho odmietli, alebo priveľký, aby sme ho
            nezvládli zrealizovať. Ak potrebujete webstránku za dostupnú cenu,
            ozvite sa nám pre nezáväznú cenovú ponuku.
          </p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
        <div
          className={
            "services-description slide-2 " +
            (currentSlide == 2 ? "active" : "")
          }
        >
          <img class="img" src={SVG2} alt="Eshop" loading="lazy" />
          {/*<StaticImage className="img"  src="../../images/new-web/web.svg" alt="Webové stránky"  />*/}
          <h3>Eshop</h3>
          <p>
            Potrebujete pomôcť s výrobou nového alebo prerobením starého eshopu?
            Vyhotovíme vám Prestashop, Magento alebo eshop na mieru za dostupnú
            cenu.
          </p>
          <p>
            Pomôžeme vám s vložením produktov, obrázkov, prepojením so skladom,
            fakturáciou a pripojením ďalších aplikácií a programov. Postaráme sa
            o SEO, reklamy na Googli, sociálnych sieťach a rast vášho zisku.
          </p>
          <p>
            Ak potrebujete eshop za dostupnú cenu, sme pre vás k dispozícii.
          </p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
        <div
          className={
            "services-description slide-3 " +
            (currentSlide == 3 ? "active" : "")
          }
        >
          <img class="img" src={SVG3} alt="Informačný systém" loading="lazy" />
          <h3>Informačný systém</h3>
          <p>
            Potrebujete systém, ktorý prepojí dáta vašich oddelení,
            zautomatizuje činnosti, umožní efektívne sledovanie firemných
            ukazovateľov, ušetrí čas, zníži náklady a pomôže k zisku?
          </p>
          <p>
            Zabezpečíme vám kompletný vývoj informačného systému. Nezáleží na
            tom, či máte záujem o malý informačný systém do jednej prevádzky
            alebo komplexný informačný systém pre viacero oddelení.
          </p>
          <p>
            Vyrábame informačné systémy s pomocou šablón a na mieru za dostupné
            ceny. Ponúkame tiež pravidelný servis, rýchlu, ľudskú komunikáciu a
            vytúžené pohodlie.
          </p>
          <p>
            Ak potrebujete informačný systém za dostupnú cenu, kontaktujte nás.
          </p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
        <div
          className={
            "services-description slide-4 " +
            (currentSlide == 4 ? "active" : "")
          }
        >
          <img
            class="img"
            src={SVG1}
            alt="Klientská zóna a B2B portál"
            loading="lazy"
          />
          <h3>Klientská zóna a B2B portál</h3>
          <p>
            Potrebujete vytvoriť Klientsku alebo Zákaznícku zónu? Alebo B2B
            portál pre vaše podnikanie? Vytvoríme pre vás riešenie, ktoré
            zautomatizuje rutinné procesy, zjednoduší komunikáciu, odstráni
            chybovosť a uvoľní ruky vášmu obchodnému a servisnému oddeleniu.
          </p>
          <p>
            Ušetrite čas, náklady a zvýšte zisky a lojalitu zákazníkov pomocou
            tohto riešenia.
          </p>
          <p>
            Vytvoríme klientsku, zákaznícku zónu alebo B2B portál za dostupnú
            cenu a poradíme vám ako z nich vyťažiť maximum.
          </p>
          <p>
            Ak potrebujete jedno z týchto riešení, kontaktujte nás a získajte
            náskok pred konkurenciou.
          </p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
        <div
          className={
            "services-description slide-5 " +
            (currentSlide == 5 ? "active" : "")
          }
        >
          <img class="img" src={SVG2} alt="Mobilná aplikácia" loading="lazy" />
          <h3>Mobilná aplikácia</h3>
          <p>
            Potrebujete mobilnú aplikáciu za dostupnú cenu? Vytvárali sme mnohé
            aplikácie, či už natívne alebo webové. Na QR kód ale ja bez neho.
            Potrebujete samostatnú aplikáciu alebo aplikáciu ako súčasť
            informačného systému?
          </p>
          <p>
            Vieme vám pomôcť s vytvorením MVP riešenia, konkrétnej aplikácie
            produktu, ale aj poradiť s jej propagáciou a úspechom na trhu.
          </p>
          <p>
            Či ste malá firma, start-up alebo etablovaná spoločnosť, sme pre vás
            k dispozícii.
          </p>
          <p>V prípade potreby nás kontaktujte pre nezáväznú cenovú ponuku.</p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
        <div
          className={
            "services-description slide-6 " +
            (currentSlide == 6 ? "active" : "")
          }
        >
          <img class="img" src={SVG3} alt="IT Outsourcing" loading="lazy" />
          <h3>IT Outsourcing</h3>
          <p>
            Máte záujem o pomoc s vývojom vášho projektu? Potrebujete krátkodobú
            alebo dlhodobú výpomoc človeka alebo celého tímu?
          </p>
          <p>
            Máme vlastné, ale aj partnerské kapacity na výpomoc pre vaše
            projekty.
          </p>
          <p>Potrebujete React, PHP, Java alebo iných developerov?</p>
          <p>
            Ozvite sa nám a poskytneme vám anonymizované životopisy vhodných
            kandidátov na IT Outsourcing za dostupné ceny.
          </p>
          <Link className="contact-button" to="/kontakt">
            Získať cenovú ponuku
          </Link>
        </div>
      </section>

      <section id="sluzby-mobile">
        <h2>Čo pre vás vieme spraviť</h2>
        <Swiper
          modules={[Pagination, Scrollbar, Autoplay]}
          slidesPerView={1}
          spaceBetween={2}
          loop={true}
          direction={"horizontal"}
          pagination={{ clickable: true }}
          autoplay={{ delay: 20000 }}
        >
          <SwiperSlide>
            <div class="img-fit">
              <img class="img" src={SVG1} alt="Webstránka" loading="lazy" />
            </div>
            <h3>Webstránka</h3>
            <p>
              Potrebujete pomôcť s výrobou webstránky? Vytvoríme pre vás
              jednoduchú, ale aj zložitú webstránku. Zvládneme aj šablónovú
              webstránku, Wordpress, ale aj webstránku s grafikou na mieru.
            </p>
            <br />
            <p>
              Popasujeme sa pre vás s jednoduchou prezentačnou webstránkou, ale
              aj komplexnou webstránkou prepojenou s účtovníctvom, skladom,
              programami tretích strán a API prepojeniami.
            </p>
            <br />
            <p>
              Sme komplexný dodávateľ a partner webstránok. Žiadny projekt pre
              nás nie je malý, aby sme ho odmietli, alebo priveľký, aby sme ho
              nezvládli zrealizovať. Ak potrebujete webstránku za dostupnú cenu,
              ozvite sa nám pre nezáväznú cenovú ponuku.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <div class="img-fit">
              <img class="img" src={SVG2} alt="Eshop" loading="lazy" />
            </div>
            <h3>Eshop</h3>
            <p>
              Potrebujete pomôcť s výrobou nového alebo prerobením starého
              eshopu? Vyhotovíme vám Prestashop, Magento alebo eshop na mieru za
              dostupnú cenu.
            </p>
            <br />
            <p>
              Pomôžeme vám s vložením produktov, obrázkov, prepojením so
              skladom, fakturáciou a pripojením ďalších aplikácií a programov.
              Postaráme sa o SEO, reklamy na Googli, sociálnych sieťach a rast
              vášho zisku.
            </p>
            <br />
            <p>
              Ak potrebujete eshop za dostupnú cenu, sme pre vás k dispozícii.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <div class="img-fit">
              <img
                class="img"
                src={SVG3}
                alt="Informačný systém"
                loading="lazy"
              />
            </div>
            <h3>Informačný systém</h3>
            <p>
              Potrebujete systém, ktorý prepojí dáta vašich oddelení,
              zautomatizuje činnosti, umožní efektívne sledovanie firemných
              ukazovateľov, ušetrí čas, zníži náklady a pomôže k zisku?
            </p>
            <br />
            <p>
              Zabezpečíme vám kompletný vývoj informačného systému. Nezáleží na
              tom, či máte záujem o malý informačný systém do jednej prevádzky
              alebo komplexný informačný systém pre viacero oddelení.
            </p>
            <br />
            <p>
              Vyrábame informačné systémy s pomocou šablón a na mieru za
              dostupné ceny. Ponúkame tiež pravidelný servis, rýchlu, ľudskú
              komunikáciu a vytúžené pohodlie.
            </p>
            <br />
            <p>
              Ak potrebujete informačný systém za dostupnú cenu, kontaktujte
              nás.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <div class="img-fit">
              <img
                class="img"
                src={SVG1}
                alt="Klientská zóna a B2B portál"
                loading="lazy"
              />
            </div>
            <h3>Klientská zóna a B2B portál</h3>
            <p>
              Potrebujete vytvoriť Klientsku alebo Zákaznícku zónu? Alebo B2B
              portál pre vaše podnikanie? Vytvoríme pre vás riešenie, ktoré
              zautomatizuje rutinné procesy, zjednoduší komunikáciu, odstráni
              chybovosť a uvoľní ruky vášmu obchodnému a servisnému oddeleniu.
            </p>
            <br />
            <p>
              Ušetrite čas, náklady a zvýšte zisky a lojalitu zákazníkov pomocou
              tohto riešenia.
            </p>
            <br />
            <p>
              Vytvoríme klientsku, zákaznícku zónu alebo B2B portál za dostupnú
              cenu a poradíme vám ako z nich vyťažiť maximum.
            </p>
            <br />
            <p>
              Ak potrebujete jedno z týchto riešení, kontaktujte nás a získajte
              náskok pred konkurenciou.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <div class="img-fit">
              <img
                class="img"
                src={SVG2}
                alt="Mobilná aplikácia"
                loading="lazy"
              />
            </div>
            <h3>Mobilná aplikácia</h3>
            <p>
              Potrebujete mobilnú aplikáciu za dostupnú cenu? Vytvárali sme
              mnohé aplikácie, či už natívne alebo webové. Na QR kód ale ja bez
              neho. Potrebujete samostatnú aplikáciu alebo aplikáciu ako súčasť
              informačného systému?
            </p>
            <br />
            <p>
              Vieme vám pomôcť s vytvorením MVP riešenia, konkrétnej aplikácie
              produktu, ale aj poradiť s jej propagáciou a úspechom na trhu.
            </p>
            <br />
            <p>
              Či ste malá firma, start-up alebo etablovaná spoločnosť, sme pre
              vás k dispozícii.
            </p>
            <br />
            <p>
              V prípade potreby nás kontaktujte pre nezáväznú cenovú ponuku.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <div class="img-fit">
              <img class="img" src={SVG3} alt="IT Outsourcing" loading="lazy" />
            </div>
            <h3>IT Outsourcing</h3>
            <p>
              Máte záujem o pomoc s vývojom vášho projektu? Potrebujete
              krátkodobú alebo dlhodobú výpomoc človeka alebo celého tímu?
            </p>
            <br />
            <p>
              Máme vlastné, ale aj partnerské kapacity na výpomoc pre vaše
              projekty.
            </p>
            <br />
            <p>Potrebujete React, PHP, Java alebo iných developerov?</p>
            <br />
            <p>
              Ozvite sa nám a poskytneme vám anonymizované životopisy vhodných
              kandidátov na IT Outsourcing za dostupné ceny.
            </p>
            <Link class="contact-button" to="/kontakt">
              Získať cenovú ponuku
            </Link>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default SluzbyShowcase;
