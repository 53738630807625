import React from "react"
import Lottie from "react-lottie"

const LottieImage = (props) => {


    const lottieOptions = {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: props.data,
      };
    
      return (
        <Lottie 
            options={lottieOptions}
            className="lottie" 
        />
      )

}

export default LottieImage;
