import React from "react";

const VideoReferences = () => {
  const videoCookieClick = () => {
    if (window.cookiesGuard.marketing == "") {
      //ak nie, tak zobraz cookie výber
      window.cookiesGuard.showPopUp();
    }
  };
  return (
    <section id="video-references">
      <h2>Čo o nás hovoria klienti?</h2>
      <div className="container">
        {/* <div id="video-ref-1" className="video-c">
          <iframe
            frameborder="0"
            src="https://www.youtube-nocookie.com/embed/ClCBkuDjI8s"
            title="YouTube video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </div>
        <div id="video-ref-2" className="video-c">
          <iframe
            frameborder="0"
            src="https://www.youtube-nocookie.com/embed/s5QfFaMfkmc"
            title="YouTube video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen="allowfullscreen"
          ></iframe>
        </div> */}

        {/* Skript pre videa sa nachadza v súbore index.js !!! */}
        <div
          id="ClCBkuDjI8s"
          className="video-c"
          onClick={() => videoCookieClick()}
        ></div>

        <div
          id="s5QfFaMfkmc"
          className="video-c"
          onClick={() => videoCookieClick()}
        ></div>

        <div className="reviews-container">
          <h3>Hodnotenia na Google</h3>
          <span className="star-span"></span>
          <br />
          <a
            className="contact-button"
            href="https://www.google.com/search?q=obs+slovakia&ie=UTF-8#lrd=0x476c899f93bfc197:0x8b01baae1d47e03b,1,,,,&wptab=si:ACFMAn-JlfkjHh0ezdNAbIMBZMsz2pZUCvXAg_gwiJ8w9ZZwYsOQjMfQr7TwYr9M8uh75wH-q9ih-EDuEdybsqIXtXhg0gH96h7U2PjKEGooaN2X9w8K2-U6J1N18sz9bK8yghZkLtwP36auiUqys7z-AR5qO4metA%3D%3D"
            target="_blank"
          >
            Pozrieť
          </a>
        </div>
      </div>
    </section>
  );
};

export default VideoReferences;
