import React from "react";
import { Link } from "gatsby";
import lottie3 from "../../lotties/lottie03.json";
import lottie2 from "../../lotties/lottie02.json";
import lottie1 from "../../lotties/lottie01.json";
import SolutionBox from "./solution-box.js";

const SolutionsSection = () => {
  return (
    <section id="online-business-solutions">
      <h2>Spolupráca s nami</h2>
      <SolutionBox
        lottieAnim={lottie3}
        align="left"
        heading="Jednoduchosť a prehľadnosť"
        desc="Vytvárame pre vás jednoduché a prehľadné riešenia, ktoré zvládne používať každý. Aj generálna riaditeľka Mirka, aj pani upratovačka Janka. Vyrábame riešenia pre vás. Pre ľudí, nie pre ITčkárov. …aj keď občas sú našimi zákazníkmi aj IT firmy 🙂"
      />

      <SolutionBox
        lottieAnim={lottie2}
        align="right"
        heading="Cenová dostupnosť"
        desc="Sme dostupný pre malé, stredné, ale aj veľké spoločnosti. Vážime si každú spoluprácu, pretože vieme, aké ťažké je zarobiť každé ďalšie euro."
      />

      <SolutionBox
        lottieAnim={lottie1}
        align="left"
        heading="Komunikácia"
        desc="Komunikujeme priamo, transparentne, nezatajujeme ceny, nehráme sa na mŕtvého chrobáka a dopredu komunikujeme prípadné nezrovnalosti. U nás sa vám nestane, že vám príde faktúra bez vášho vedomia a odsúhlasenia."
      />
      <Link className="contact-button" to="/o-spolocnosti">
        Zistite o nás viac
      </Link>
    </section>
  );
};

export default SolutionsSection;
